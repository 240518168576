import './App.css';
import './assets/css/06217.css'
import './assets/css/d7cb9.css'
import './assets/css/37929.css'
import './assets/css/2a4c8.css'
import './assets/css/f44e0.css'
import './assets/css/fonts.css'
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import cn from 'classnames'
import { About } from './components/About';
import { Menu } from './components/Menu';
import { Home } from './components/Home';
import { Reviews } from './components/Reviews';
import { Price } from './components/Price';
import { Contacts } from './components/Contacts';
import { Footer } from './components/Footer';
import { Head } from './components/Head';
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import { resources } from './lang';
import { Social } from './components/Social';
import useWindowDimensions from './utils/hooks';
import { isSemagin } from './helper';
import { Policy } from './components/Policy';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: isSemagin() ? "ru" : "en",
    supportedLngs: isSemagin() ? ['ru', 'en'] : ['en']
  });

function App() {
  const [showMenu, setShowMenu] = useState(true)

  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  useEffect(() => {
    setShowMenu(!isMobile)
  }, [isMobile])

  return (
    <Router>
      <Head />

      {isMobile && <div id="sticky_header">
        <div className={cn("menu-toggle", { on: showMenu })} onClick={() => setShowMenu((p) => !p)}>
          <div className="line-one" />
          <div className="line-two" />
          <div className="line-three" />
        </div>
      </div>
      }

      {showMenu && <Menu />}

      <Social />

      <div id="content">
        <div id="primary">
          <div className="container">
            <main id="main">
              <section className="page type-page status-publish hentry">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/reviews" element={<Reviews />} />
                  <Route path="/price" element={<Price />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/privacy-policy" element={<Policy />} />
                </Routes>
              </section>
            </main>
          </div>
        </div>
      </div>

      <Footer />
    </Router >
  );
}

export default App;
