import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isSemagin } from "../helper";

export const slideAmount = 16

export const Slider = () => {
    const initialSlide = 1
    const { t } = useTranslation()

    const [index, setIndex] = useState(initialSlide)

    const nextSlide = useCallback(() => setIndex(index === slideAmount ? initialSlide : (index) => index + 1), [index])
    const prevSlide = () => setIndex(index === 1 ? slideAmount : (index) => index - 1)

    useEffect(() => {
        const interval = setTimeout(() => {
            nextSlide()
        }, 3500)

        return () => clearTimeout(interval);
    }, [index, nextSlide]);

    return (
        <>
            <div id="site-branding" style={{
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%)',
                width: 'fit-content'
            }}>
                <h1 id="site-title">
                    <a href="/" t rel="home">{isSemagin() ? 'SEMAGIN.COM' : 'SEMACHKOVSKY.COM'}</a>
                </h1>
                <p id="site-description">{t('PhotographerPhuket')}</p>
            </div>
            <div className="slide-container">
                <div style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '15%',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}

                    onClick={() => prevSlide()}
                >
                    <svg style={{ marginLeft: '5px' }} width="48" height="48" viewBox="0 0 24 24" fill="white">
                        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z">
                        </path>
                    </svg>
                </div>
                <div style={{
                    backgroundImage: `url(slider/${index}.webp)`,
                    height: '100vh',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    transition: 'background-image 0.8s ease-in-out'
                }} />

                <div style={{ backgroundImage: `url(slider/${index + 1}.webp)` }} />

                <div style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    height: '100%',
                    width: '15%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row-reverse',
                    cursor: 'pointer'
                }}
                    onClick={() => nextSlide()}
                >
                    <svg style={{ marginRight: '5px' }} fill="white" width="48" height="48" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg>
                </div>
            </div>
        </>
    )
}