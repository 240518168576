
import insta from '../assets/img/insta.png'
import wsup from '../assets/img/whatsapp.png'
import telega from '../assets/img/telegram.png'
import { useTranslation } from 'react-i18next';

export const Contacts = () => {
    const { t } = useTranslation();

    return (<div className="entry-content" style={{ textAlign: "center" }}>
        <div className="contact-icons">
            <div>
                <h2 className="section-title">WhatsApp</h2>
                <div className="row" style={{ margin: '0px 30px 30px 30px' }}>
                    <a className="whats " href="https://wa.me/66805206760">
                        <img decoding="async" className="revealator-slideleft revealator-delay1 revealator-duration10 revealator-once revealator-within" src={wsup} width="54" height="54" alt="" />
                    </a>
                </div>
            </div>
            <div>
                <h3 className="section-title">INSTAGRAM</h3>
                <div className="row" style={{ margin: "0px 30px 30px 30px" }}>
                    <a className="whats" href="https://www.instagram.com/semagin_photo/">
                        <img decoding="async" loading="lazy" className="revealator-slideleft revealator-delay6 revealator-duration11 revealator-once revealator-within" src={insta} width="60" height="60" alt="insta" />
                    </a>
                </div>
            </div>
            <div>
                <h2 className="section-title">Telegram</h2>
                <div className="row" style={{ margin: "0px 30px 30px 30px" }}>
                    <a className="whats " href="https://tlgg.ru/@semagin_photo">
                        <img decoding="async" loading="lazy" className="revealator-slideleft revealator-delay1 revealator-duration10 revealator-once revealator-within alignnone" src={telega} alt="" width="54" height="54" />
                    </a>
                </div>
            </div>
        </div>
        <center className="row revealator-zoomin revealator-delay15 revealator-duration10 revealator-once revealator-within" style={{ marginTop: "40px" }}>
            <h4>{t('contacts1')}</h4>
            <h4>+66 80 520 67 60</h4>
            <h4>semaginnn@gmail.com</h4>
            <h4>{t('contacts2')}</h4>
        </center>
    </div >)
}