import instHead from '../assets/img/inst_header.png'

export const Home = () => <>
    <a href="https://www.instagram.com/semagin_photo/" target="_blank" rel="noreferrer">
        <img src={instHead} alt="insta" style={{ marginBottom: '30px' }} />

        <div className='insta-grid'>
            <div className='item'>
                <img className='item_content' src="insta/1.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/2.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/3.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/4.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/5.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/6.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/7.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/8.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/9.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/10.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/11.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/12.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/13.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/14.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/15.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/16.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/17.webp" alt="" />
            </div>
            <div className='item'>
                <img className='item_content' src="insta/18.webp" alt="" />
            </div>
        </div>
    </a>
</>