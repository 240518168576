
import i1 from '../assets/img/zegqcI6tJFo.jpeg'
import i2 from '../assets/img/ZBqr9jtGgak.jpeg'
import i3 from '../assets/img/xSuUS4C6vAo.jpeg'
import i4 from '../assets/img/uRTMAXW-2A.jpeg'
import i5 from '../assets/img/SY4_Z0HDXV4.jpeg'
import i6 from '../assets/img/SQogZ6Asb4Y.jpeg'
import i7 from '../assets/img/nF3w7u1zq0I.jpeg'
import i8 from '../assets/img/nGGWZ7J9kwU.jpeg'
import i9 from '../assets/img/nv4UBCJwrJI.jpeg'
import i10 from '../assets/img/rHMaiEr56oA.jpeg'
import i11 from '../assets/img/rPcAwiL-hz4.jpeg'
import i12 from '../assets/img/S4F6Jjqh0-k.jpeg'
import i13 from '../assets/img/dGpZ48YiBzU.jpeg'
import i14 from '../assets/img/ndnmoc8aEeI.jpeg'
import i15 from '../assets/img/MRsf07baQzs.jpeg'
import i16 from '../assets/img/C5afec9qpLg.jpeg'
import i17 from '../assets/img/bmeIQk6KnoM.jpeg'
import i18 from '../assets/img/kaG4BaprGno.jpeg'
import i19 from '../assets/img/FuzjdfB8rco.jpeg'
import i20 from '../assets/img/ft3fu6wGBxQ.jpeg'
import i21 from '../assets/img/dTnR0tdQzP8.jpeg'

import g1 from '../assets/img/Без-имени-2-1.jpeg'
import g2 from '../assets/img/Без-имени-3-1.jpeg'
import g3 from '../assets/img/Без-имени-5-1.jpeg'
import g4 from '../assets/img/Без-имени-54-1.jpeg'
import g5 from '../assets/img/Без-имени-211-1.jpeg'
import g6 from '../assets/img/Без-имени-23452563-1.jpeg'


export const Reviews = () => <div className="entry-content clearfix">
    <figure className="is-layout-flex wp-block-gallery-1 wp-block-gallery columns-3 is-cropped">
        <ul className="blocks-gallery-grid"><li className="blocks-gallery-item"><figure>
            <img decoding="async" width="576" height="1024" src={i1} alt="" className="wp-image-639" />
        </figure>
        </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i2} alt="" className="wp-image-638" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="498" height="1024" src={i3} alt="" className="wp-image-637" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i4} alt="" className="wp-image-636" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="498" height="1024" src={i5} alt="" className="wp-image-635" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i6} alt="" className="wp-image-634" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i7} alt="" className="wp-image-628" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i8} alt="" className="wp-image-629" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i9} alt="" className="wp-image-630" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i10} alt="" className="wp-image-631" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i11} alt="" className="wp-image-632" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="498" height="1024" src={i12} alt="" className="wp-image-633" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i13} alt="" className="wp-image-621" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="498" height="1024" src={i14} alt="" className="wp-image-627" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i15} alt="" className="wp-image-626" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i16} alt="" className="wp-image-620" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="498" height="1024" src={i17} alt="" className="wp-image-619" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i18} alt="" className="wp-image-625" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i19} alt="" className="wp-image-624" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i20} alt="" className="wp-image-623" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="576" height="1024" src={i21} alt="" className="wp-image-622" />
                </figure>
            </li>
        </ul>
    </figure>
    <figure className="is-layout-flex wp-block-gallery-3 wp-block-gallery columns-1 is-cropped">
        <ul className="blocks-gallery-grid">
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="665" height="275" src={g1} alt="" className="wp-image-679" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="663" height="368" src={g2} alt="" className="wp-image-680" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure><img decoding="async" loading="lazy" width="678" height="234" src={g3} alt="" className="wp-image-681" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="677" height="328" src={g4} alt="" className="wp-image-682" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="674" height="218" src={g5} alt="" className="wp-image-683" />
                </figure>
            </li>
            <li className="blocks-gallery-item">
                <figure>
                    <img decoding="async" loading="lazy" width="674" height="218" src={g6} alt="" className="wp-image-684" />
                </figure>
            </li>
        </ul>
    </figure>
</div>