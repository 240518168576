import { useTranslation } from "react-i18next";

export const Price = () => {
    const { t } = useTranslation();

    return (
        <div className="entry-content clearfix">
            <p className="has-medium-font-size">{t('price1')}</p>
            <br />
            <div className="is-layout-flex wp-container-4 wp-block-columns">
                <div className="is-layout-flow wp-block-column">
                    <p className="has-medium-font-size">
                        <b>{t('price2')}</b>.
                    </p>
                    <p className="has-medium-font-size">{t('price3')}</p>
                    <p className="has-medium-font-size">• {t('hour1')}<br />• {t('help')}<br />• {t('raw')}<br />• {t('upTo')} 15 {t('retouched')}<br />• {t('deadline5')}</p>
                </div>
                <div className="is-layout-flow wp-block-column">
                    <p className="has-medium-font-size"><b>{t('price4')}</b>.</p>
                    <p className="has-medium-font-size">{t('price5')}</p>
                    <p className="has-medium-font-size">• {t('hour2')}<br />• {t('help')}<br />• {t('raw')}<br />• {t('upTo')} 30 {t('retouched')}<br />• {t('deadline5')}</p>
                </div>
                <div className="is-layout-flow wp-block-column">
                    <p className="has-medium-font-size"><b>{t('price6')}</b>.</p>
                    <p className="has-medium-font-size">• {t('price7')}<br />• {t('price9')}<br />• {t('price10')}</p>
                </div>
            </div>
            <p className="has-medium-font-size"><b>{t('price17')}</b>.</p>
            <div className="wp-block-columns">
                <div className="wp-block-column">
                    <p className="has-medium-font-size">{t('price18')}</p>
                    <p className="has-medium-font-size">
                        • {t('hour2')}<br />
                        • {t('price19')}<br />
                        • {t('raw')}<br />
                        • {t('upTo')} 35 {t('retouched')}<br />
                        • {t('deadline5')}
                    </p>
                </div>
            </div>
            <p className="has-medium-font-size"><b>{t('price11')}</b>.</p>
            <div className="wp-block-columns">
                <div className="wp-block-column">
                    <p className="has-medium-font-size">{t('price12')}</p>
                    <p className="has-medium-font-size">• {t('hour2')}<br />• {t('price13')}<br />• {t('raw')}<br />• {t('upTo')} 30 {t('retouched')}<br />• {t('deadline3')}</p>
                </div>
            </div>
            <div className="wp-block-columns">
                <div className="wp-block-column">
                    <p className="has-medium-font-size"><b>{t('price14')}</b>.</p>
                    <p className="has-medium-font-size">{t('price15')}</p>
                    <p className="has-medium-font-size">• {t('hour6')}<br />• {t('price16')}<br />• {t('raw')}<br />• {t('upTo')} 150 {t('retouched')}<br />• {t('deadline3')}</p>
                </div>
            </div>
        </div>
    )
}