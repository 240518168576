import footer from '../assets/img/footer.jpg'
import { isSemagin } from '../helper'

export const Footer = () => <footer>
    <div id="site-branding">
        <h2 id="site-title">
            <div style={{ color: 'black' }}>{isSemagin() ? 'SEMAGIN.COM' : 'SEMACHKOVSKY.COM'}</div>
        </h2>
        <div className="social-links">
            <ul>
                <li>
                    <a target="_blank" href="https://wa.me/66805206760" rel="noreferrer">
                        <i className="fa fa-facebook">
                        </i>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://tlgg.ru/@semagin_photo" rel="noreferrer">
                        <i className="fa fa-twitter">
                        </i>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.instagram.com/semagin_photo/" rel="noreferrer">
                        <i className="fa fa-instagram">
                        </i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <img src={footer} alt="footer" />
</footer>