export const Policy = () => (<div className="entry-content clearfix">
    <p>Photographer Nikita Semagin (hereinafter referred to as “PHOTOGRAPH”), thanks you for your interest in our services and for visiting our website.</p>

    <p>
        Your submission of personal data means your unconditional consent to the provisions of this Privacy Policy. If you do not agree with the terms of the Privacy Policy, you should immediately stop using this site.
        This Privacy Policy (hereinafter referred to as Privacy Policy) applies to all information that is posted on this site (domain name https://semachkovsky.com/), as well as that can be obtained about the customer during his use of the site, programs and products of the site.
        This Privacy Policy is designed to indicate the list of data that may be requested from the client, as well as the ways of processing such data by PHOTOGRAPH and other persons. PHOTOGRAPH ensures the security of personal information received from customers (service users).
        This Privacy Policy also specifies the purposes for which clients' personal information may be requested or disclosed. Separate agreements with clients may stipulate other purposes for which personal information of clients may be requested or disclosed.
        This Privacy Policy also specifies the basic precautions that customers should take to ensure that their personal information remains confidential. This Policy applies - to information that has been obtained by PHOTOGRAPH as a result of a client's use of the PHOTOGRAPH website.
    </p>

    <p>
        1. Customer information received and used, as well as the purposes of its use: Customer information is collected by PHOTOGRAPH for the purposes of identifying the customer, placing an order, establishing feedback with the customer, creating a customer account, notifying the customer of the status of the order, processing and receiving payments, providing customer support, and analyzing and improving performance; to provide customers with information about the services provided; for marketing purposes; and for other purposes specified in this Privacy Policy or the terms of use of PHOTOGRAPH's services. In this Privacy Policy, customer information means: personal information that the customer independently provides to PHOTOGRAPH when creating an account, registration, etc., as well as in the process of using PHOTOGRAPH's services (name, gender, e-mail address, phone number, etc.); as well as automatically transmitted data in the process of using the site, including, but not limited to: IP address, information about the mobile device from which access is made, information from cookies, information about the browser (or other program that accesses the display of advertisements), access time, address of the page where the advertising block is located, referrer (address of the previous page), etc, When using the site, the following information may be requested and received:
        Customer Information. When creating an account and/or registering, the following information is requested customer information such as full name, gender, date of birth, e-mail address, telephone number. Additional information may also be requested. If the customer consents separately, information about the customer's contact information (phone and/or address book, contacts in a mobile device) may be obtained.
        Location information. Services that support the geographic location function of the client's mobile device allow receiving information about the actual location of the client, including GPRS data sent by the mobile device.
        Mobile Device Information. Customer mobile device information such as mobile device model, operating system version, unique device identifiers, and mobile network and cell phone number are collected, In addition, the device identifier and cell phone number may be linked to a customer account.
        When using the client's information, PHOTOGRAPH is guided by this Privacy Policy, the Regulation on the protection of clients' personal data during their processing, as well as the legislation of the Russian Federation.
        Processing of client's personal data shall be carried out without limitation of time, by any lawful means, including in information systems of personal data with or without the use of means of automation.
    </p>

    <p>
        2. Provision of clients' information to third parties. PHOTOGRAPH has the right to provide information of clients to its affiliates, as well as partners and other companies related to PHOTOGRAPH for the purposes specified above. In doing so, PHOTOGRAPH's affiliates, as well as companies associated with PHOTOGRAPH, are bound by this Privacy Policy. PHOTOGRAPH does not share customer information with companies and individuals
        persons not affiliated with PHOTOGRAPH, except as listed below.
        - Customer has given its consent to do so. In order to provide PHOTOGRAPH with client information to companies and individuals not affiliated with PHOTOGRAPH, including other clients, additional consent of the client is requested, the Client may withdraw this consent at any time.
        - As required by applicable law. PHOTOGRAPH shall be provided with client information in the event that the receipt, use and disclosure of such information is necessary for the purpose of: - ' to comply with and enforce applicable law, court orders, or to fulfill the lawful requests of governmental authorities; ' to detect, deter, or otherwise prevent crime, violation of law, including fraud, or to correct technical or security failures; ' to protect the rights, property, or safety of PHOTOGRAPH, PHOTOGRAPH's customers to the extent permitted by applicable law.
        PHOTOGRAPH may provide aggregated, anonymized customer data to partners (e.g., for the purpose of conducting statistical and other research, arranging for delivery, engaging contractors, etc.). When transferring customer information abroad, PHOTOGRAPH shall ensure compliance with applicable laws and these Regulations with respect to customer information by entering into contracts that ensure that the recipients of the information adhere to an appropriate level of protection.
    </p>

    <p>3. Security measures used to maintain the confidentiality of information. PHOTOGRAPH shall take all possible measures to ensure the security and protection of client information from unauthorized attempts to access, modify, disclose or destroy it, as well as other types of improper use. In particular, PHOTOGRAPH continually improves the way it collects, stores and processes data, including physical security measures, to prevent unauthorized access to PHOTOGRAPH's systems for the purposes of theft, phishing and other fraud. PHOTOGRAPH also restricts access by employees, contractors, and agents to customer information by providing strict contractual confidentiality obligations, with liability and penalties for violations.</p>

    <p>4. Modification of the Privacy Policy. Applicable Law. PHOTOGRAPH has the right to update and amend the provisions of this Privacy Policy at any time. The new edition of the Privacy Policy comes into effect from the moment of its posting, unless otherwise provided by the provisions of the new edition of the Privacy Policy PHOTOGRAPH recommends its clients to regularly refer to this Privacy Policy in order to familiarize themselves with the most current edition.</p>

    <p>5. PHOTOGRAPH does not control and is not responsible for third party websites to which the client may access through links available on the PHOTOGRAPH website. PHOTOGRAPH does not verify the validity of personal data provided by the client of the site.</p>

    <p>6. Dispute Resolution. Before applying to the court with a claim on disputes arising from the relations between the client of the Internet site and the PHOTOGRAPH it is obligatory to submit a claim (a written offer of voluntary dispute settlement). The recipient of the claim within 30 calendar days from the date of receipt of the claim shall notify the claimant in writing of the results of consideration of the claim. If no agreement is reached, the dispute shall be referred to a judicial body in accordance with the current legislation of the Russian Federation.</p>

</div>)
