export const resources = {
    en: {
        translation: {
            "Home": "Home",
            "About": "About",
            "Reviews": "Reviews",
            "Price": "Price",
            "Contacts": "Contacts",
            "PhotographerPhuket": "Photographer Phuket",
            "about1": "Hello dear! I am Nikita Semagin. I’ve been working as a professional photographer since 2009. Since 2015 I’ve been living Phuket.",
            "about2": "Performed more than 1000 photo shoots for tourists, barbers, hotels, clinics, spa, apartments and villas.",
            "about3": "Have a high-quality photo equipment (Canon, Sony).",
            "about4": "Use compact studio light Godox and Falcon eyes. It can be used any places.",
            "about5": "We can make photo session any locations you choose. Help with your posing, movement and mimic and give you image advice.",
            "about6": "Help with makeup.",
            "about7": "Individual approach for companies.",
            "about8": "You may see my photo shoots examples and reviews on this site. For more photos check out my instagram.",
            "price1": "The price depends on photo session duration, location, how many people involved and complexity of retouch. Any places can be chosen villas, beach, yachts, luxury hotels.",
            "price2": "Standart",
            "price3": "180 $ or 6200 baht",
            "price4": "Basic",
            "price5": "320$ or 11000 baht",
            "price6": "Additional for Standart or Basic",
            "price7": "remote locations paid extra (Kamala 800 baht – Mai Khao 2000 baht)",
            "price8": "dresses and accessories for rent from $50",
            "price9": "extra retouch 300 baht per photo",
            "price10": "transfer starts from 1500 baht",
            "price11": "Villa",
            "price12": "500$ or 16500 baht",
            "price13": "private tropical villa with swimming pool",
            "price14": "Yacht",
            "price15": "2500 $ or 90000 baht",
            "price16": "sailing yacht 40 feet, lunch and drinks included",
            "price17": "Island",
            "price18": "500$ or 16500 baht",
            "price19": "tropical island. The boat and entrance are included.",
            "hour1": "1 hour",
            "hour2": "2 hour",
            "hour6": "6 hour",
            "help": "help with appearance, location",
            "raw": "all raw photos",
            "upTo": "up to",
            "retouched": "retouched photos",
            "deadline3": "deadline up to 3 days",
            "deadline5": "deadline up to 5 days",
            "contacts1": "I’m on Phuket",
            "contacts2": "Glad to work with you",
        }
    },
    ru: {
        translation: {
            "Home": "Главная",
            "About": "О себе",
            "Reviews": "Отзывы",
            "Price": "Прайс",
            "Contacts": "Контакты",
            "PhotographerPhuket": "Фотограф Пхукет",
            "about1": "Друзья Здравствуйте! Моё имя Никита Семагин. С 2009 года я занимаюсь профессиональной фотосъемкой, с 2015 года живу и работаю в Таиланде на острове Пхукет.",
            "about2": "Провел более 1000 съемок на острове для туристов отелей, барбершопов, офисов, клиник, клиник красоты, жилых комплексов и комплексов вилл.",
            "about3": "Имею обширную линейку объективов высокого класса (Canon, Sony). ",
            "about4": "Я использую мобильный студийный свет Godox и Falcon eyes, который можно использовать в любой локации.",
            "about5": "Фотосессия может проходить в любой локации которая вам интересна . Полностью помогаю вам с позированием, движением и мимикой. Дам советы по подбору образа.",
            "about6": "Помогу с подбором для вас хорошего визажиста.",
            "about7": "Для организаций предложу индивидуальный подход с созданием стиля, концепции.",
            "about8": "На моем сайте Вы можете посмотреть примеры моих работ, отзывы , связаться со мной, более обширное портфолио представлено в моем инстаграмме, почти каждый день туда попадают мои новые снимки.",
            "price1": "Цена может отличаться. Зависит от курса бата к доллару,от колличества съемочных часов. Все зависит от количества съемочных часов , территориального расположения локации в которой проходит съемка, количества человек и глубины необходимой ретуши. Возможно съемки на частных виллах, пляжах, яхтах, отелях люкс класса",
            "price2": "Пакет Стандарт",
            "price3": "120 $ или 4200 бат",
            "price4": "Пакет Базовый",
            "price5": "200$ или 8000 бат",
            "price6": "Дополнительно",
            "price7": "Отдаленные районы острова оплачиваются отдельно (Kamala 600 бат – Mai Khao 1600 бат)",
            "price8": "Платья и атрибуты в аренду от 50 $",
            "price9": "дополнительная ретушь фотографии к пакету 300 бат за фото ",
            "price10": "Трансфер от 1500 бат",
            "price11": "Пакет Вилла",
            "price12": "от 400$ или 14000 бат",
            "price13": "Съемка проходит  на частной тропической  вилле  с бассейном и терииторией",
            "price14": "Пакет Яхта",
            "price15": "от 2500 $ или 90000 бат",
            "price16": "парусная яхта 40 футов, питание, напитки, обслуживание, фотосъемка",
            "price17": "Пакет Остров",
            "price18": "350$ или 12000 бат",
            "price19": "Фотосессия проводится на тропическом острове. Лодка и вход включены в стоимость.",
            "hour1": "1 час",
            "hour2": "2 часа",
            "hour6": "6 часов",
            "help": "Помощь в подборе образа, локации",
            "raw": "Все исходные фотографии",
            "upTo": "до",
            "retouched": "фотографий в ретуши",
            "deadline3": "Срок сдачи до 3 дней",
            "deadline5": "Срок сдачи до 5 дней",
            "contacts1": "Нахожусь на Пхукете",
            "contacts2": "Рад сотрудничеству",
        }
    }
}