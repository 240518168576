import { Link, useLocation, useSearchParams } from "react-router-dom";
import cs from 'classnames'
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { isSemagin } from "../helper";

export const Menu = () => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const lng = searchParams.get("lng")
        i18n.changeLanguage(lng)
    }, [i18n, searchParams])

    const classname = "menu-item menu-item-type-custom menu-item-object-custom"
    const activeClass = "current-menu-item current_page_item"

    return (
        <div id='sticky_header' >
            <div className='container'>
                <nav className='main-navigation'>
                    <ul className='menu'>
                        <li className={cs(classname, { [activeClass]: pathname === '/' })}>
                            <Link to="/">{t('Home')}</Link>
                        </li>
                        <li className={cs(classname, { [activeClass]: pathname === '/about' })}>
                            <Link to="/about">{t('About')}</Link>
                        </li>
                        <li className={cs(classname, { [activeClass]: pathname === '/reviews' })}>
                            <Link to="/reviews">{t('Reviews')}</Link>
                        </li>
                        <li className={cs(classname, { [activeClass]: pathname === '/price' })}>
                            <Link to="/price">{t('Price')}</Link>
                        </li>
                        <li className={cs(classname, { [activeClass]: pathname === '/contacts' })}>
                            <Link to="/contacts">{t('Contacts')}</Link>
                        </li>
                        <li className={cs(classname, { [activeClass]: pathname === '/privacy-policy' })}>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        {isSemagin() && i18n.language !== 'en' &&
                            <li>
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAt1BMVEWSmb66z+18msdig8La3u+tYX9IaLc7W7BagbmcUW+kqMr/q6n+//+hsNv/lIr/jIGMnNLJyOP9/fyQttT/wb3/////aWn+YWF5kNT0oqz0i4ueqtIZNJjhvt/8gn//WVr/6+rN1+o9RKZwgcMPJpX/VFT9UEn+RUX8Ozv2Ly+FGzdYZrfU1e/8LS/lQkG/mbVUX60AE231hHtcdMb0mp3qYFTFwNu3w9prcqSURGNDaaIUMX5FNW5wYt7AAAAAjklEQVR4AR3HNUJEMQCGwf+L8RR36ajR+1+CEuvRdd8kK9MNAiRQNgJmVDAt1yM6kSzYVJUsPNssAk5N7ZFKjVNFAY4co6TAOI+kyQm+LFUEBEKKzuWUNB7rSH/rSnvOulOGk+QlXTBqMIrfYX4tSe2nP3iRa/KNK7uTmWJ5a9+erZ3d+18od4ytiZdvZyuKWy8o3UpTVAAAAABJRU5ErkJggg==" alt="English" width="16" height="11"
                                    style={{ marginTop: '5px', marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={() => i18n.changeLanguage("en")}
                                />
                            </li>
                        }
                    </ul>
                </nav>
            </div>
        </div >)
}