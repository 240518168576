import { useTranslation } from "react-i18next";
import header from '../assets/img/header.jpeg'
import { isSemagin } from "../helper";

export const Header = () => {
    const { t } = useTranslation()

    return (<div className="top-header" style={{ backgroundImage: `url(${header}` }}>
        <div className="container clearfix">
            <div id="site-branding">
                <h2 id="site-title">
                    <a href="/" rel="home">{isSemagin() ? 'SEMAGIN.COM' : 'SEMACHKOVSKY.COM'}</a>
                </h2>
                <p id="site-description">{t('PhotographerPhuket')}</p>
            </div>
        </div>
    </div>)
}