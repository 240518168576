import wsup from '../assets/img/whatsapp.png'
import telega from '../assets/img/telegram.png'

export const Social = () => <div className="sticky">
    <a className="whats" target="_blank" href="https://wa.me/66805206760" rel="noreferrer">
        <img src={wsup} alt="" decoding="async" className="revealator-slideleft revealator-delay1 revealator-duration10 revealator-once revealator-within" width="54" height="54" />
    </a>
    <a className="whats" target="_blank" href="https://tlgg.ru/@semagin_photo" rel="noreferrer">
        <img src={telega} alt="" decoding="async" loading="lazy" className="revealator-slideleft revealator-delay1 revealator-duration10 revealator-once revealator-within alignnone" width="54" height="54" />
    </a>
</div>